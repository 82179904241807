// export const umAppConfig = {
//     apiUrl: process.env.REACT_APP_AUTH_API_URL,
//     uiUrl: process.env.REACT_APP_UI_URL,
//     postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_URL,
// }
  
export const umAppConfig = {
  uiUrl: "https://aiml-usage-stats.carrier.com",
  apiUrl: "https://api.carrierappshub1.com/",
  postLogoutRedirectUri: "http://localhost:3000"
}