import { ReactAuthWrapper } from "@carrier-sce-sharedpackages/reactauthwrapper";
import "./App.css";
import { umAppConfig } from "./appConfig";
import { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material";
import { Themed } from "./theme";
import { Routing } from "./routing";
import UserContext from "./contexts/UserContext";
import { ApiService } from "./utils/ApiService";
import { GetUserProfileAndPermissions } from '@carrier-sce-sharedpackages/auth-module';
import { GetGroupDetailsById } from '@carrier-sce-sharedpackages/auth-module';

function App() {
  const [userInfo, setUsersInfo] = useState({
    data: {},
    isLoading: true,
    error: null,
  });

  useEffect(() => {
    setUsersInfo({ ...userInfo, isLoading: true });
    ApiService("user-by-token", "GET")
      .then((response) => {
        setUsersInfo({ ...userInfo, data: response.result, isLoading: false });
      })
      .catch((error) => setUsersInfo({ ...userInfo, isLoading: false, error }));
  }, []);

  const [appAccess, setAppAccess] = useState(false);
  const callback = (permissions) => {
    console.log("permissions->", permissions);
    if (permissions.appAccess) {
      console.log("permissions->", permissions.appAccess);
      setAppAccess(true);
    }
  };

  return (
    <div className="App">
      <ReactAuthWrapper appConfig={umAppConfig} getPermissions={callback}>
        <UserContext.Provider value={userInfo.data}>
          <ThemeProvider theme={Themed}>
            {appAccess && <Routing />}
          </ThemeProvider>
        </UserContext.Provider>
      </ReactAuthWrapper>
    </div>
  );
}

export default App;
